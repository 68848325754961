<template>
    <!-- 平台自定义失败状态管理 -->
    <div id="channel_state_unify_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add" size="mini">
                    增加
                </el-button>
            </div>
            <div class="head_top_title">平台自定义失败状态管理</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <!-- 查询条件 -->
            <el-row id="query_condition_id_202205200952" style="padding:0px 0;">
                <el-form  :model="form_data" :inline="true" style="margin-bottom: 0px;" label-width="68px">
                    <el-form-item label="状态" >
                        <el-input v-model="form_data.state" placeholder="默认查询全部" size="mini" style="width:88px"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            <el-row style="overflow: auto;" :style="{height: ((content_heigth-pagination_height-query_condition_height)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini">
                    <el-table-column prop="state" label="状态" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="type" label="种类" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="meaning" label="状态含义" min-width="80" align="center"> </el-table-column>
                    <el-table-column label="操作" min-width="80" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="update(scope.row)">修改</el-button>
                            <template v-if="scope.row.type == '系统' || scope.row.type == '统一返回状态'">
                                <el-button type="text" size="mini" style="color:grey">删除</el-button>
                            </template>
                            <template v-else>
                                <el-button type="text" size="mini" style="color:#f56c6c" @click="del(scope.row.state)">删除</el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
            <el-row id="pagination_id_202205200952" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10, 20, 30, 40,100]"
                     :page-size="pageSize"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>
        </el-row>

        <!-- 企信通平台自定义失败状态配置-添加状态 -->
        <ChannelStateUnifyAddUpd :prop_change_i="prop_channel_state_unify_i" :prop_change_type="prop_channel_state_unify_type"
        :prop_change_item='prop_channel_state_unify_item'
        @result="submit_cha_xun"></ChannelStateUnifyAddUpd>
    </div>
</template>
<script>
import API from '../../api/api';
import ChannelStateUnifyAddUpd from '../channel/channel_state_unify_add_upd.vue';//组件 自定义失败状态增加

export default {

    components: {
        ChannelStateUnifyAddUpd  
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            form_data:{
                state:"",//查询条件-状态 空字符表示全部
            },

            indexPage:1,//分页 第几页
            pageSize:20,//分页 一页的数量值

            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度
            query_condition_height:40,//查询条件的高度

            //企信通平台自定义失败状态配置-添加状态 组件 弹出框是否可见
            prop_channel_state_unify_i: 0,//添加状态 组件 弹出框是否可见
            prop_channel_state_unify_type: 'add',//类型：添加或修改
            prop_channel_state_unify_item: {},//状态对象
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();
        
        //查询
        this.submit_cha_xun();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);
        },
        //查询
        submit_cha_xun(){
            //请求接口
            API.ChannelStateUnifyServlet({
                param: "getList",
                state:this.form_data.state,
                indexPage:this.indexPage,
                pageSize:this.pageSize,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id_202205200952');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                        //查询条件的高度
                        var query_condition = document.getElementById('query_condition_id_202205200952');
                        if(!API.isEmtry(query_condition)){
                            this.query_condition_height = query_condition.clientHeight;
                        }
                    });
                } 
            });
        },
        //添加
        add() {
            this.prop_channel_state_unify_i++;
            this.prop_channel_state_unify_type = "add";
        },
        //删除
        del(state) {
            this.$confirm('此操作将永久删除该状态, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                //请求接口
                API.ChannelStateUnifyServlet({
                    param: "del",
                    state: state,
                }).then((res) => {
                    if (res.recode === 0) {
                        this.$message({showClose: true,message: '恭喜你，状态删除成功', type: 'success'});
                        //重新加载数据
                        this.submit_cha_xun();
                    }
                });
            }).catch(() => {//已取消删除
            });
        },
        //修改
        update(item) {
            this.prop_channel_state_unify_i++;
            this.prop_channel_state_unify_type = "upd";
            this.prop_channel_state_unify_item = item;
        },
        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        //返回
        goBack() {
            API.router_to("/channel_state_index");
        },
    }
};
</script>

<style scoped>
/*form表单样式调整*/
#app_check_rate_list>>>.el-form-item {
  margin-bottom: 0px;
}
</style>