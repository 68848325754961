<template>
    <div>
        <!-- 通道管理》失败状态转义管理》平台自定义失败状态管理 增加 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" destroy-on-close :show-close="false" width="98%" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>{{cur_title}}</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
                <el-row v-show="this.prop_change_type == 'add'">
                    <el-form  :model="form_data" label-width="150px" style="margin-bottom: 12px;">
                        <el-form-item label="状态" >
                            <el-input v-model="form_data.state" placeholder="请填写状态" size="mini" ></el-input>
                        </el-form-item>

                        <el-form-item label="状态含义">
                            <el-input v-model="form_data.meaning" placeholder="请填写状态含义" size="mini" ></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
                <el-row v-show="this.prop_change_type == 'upd'">
                    <el-form  :model="form_data_upd" label-width="150px" style="margin-bottom: 12px;">
                        <el-form-item label="状态" >
                            <el-input v-model="form_data_upd.state" placeholder="请填写状态" size="mini" disabled></el-input>
                        </el-form-item>

                        <el-form-item label="种类" >
                            <el-input v-model="form_data_upd.type" placeholder="请填写状态" size="mini" disabled></el-input>
                        </el-form-item>

                        <el-form-item label="状态含义">
                            <el-input v-model="form_data_upd.meaning" placeholder="请填写状态含义" size="mini" ></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';

export default {
    props:{
        prop_change_i:0,//添加状态 组件 弹出框是否可见
        prop_change_type:"",//类型：添加或修改
        prop_change_item:{},//状态对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见
                if(this.prop_change_type === "add"){
                    this.cur_title = "增加平台自定义失败状态";
                }else {
                    this.cur_title = "修改平台自定义失败状态";
                    setTimeout(() => {
                        //获取数据
                        this.getData();
                    }, 1);   
                }
            }
        }
    },
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头

            form_data:{
                state:"",//状态
                meaning:"",//状态的含义
            },

            form_data_upd:{
                state:"",//状态
                type:"",//状态种类
                meaning:"",//状态的含义
            },
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);  
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
         //查询
        getData(){
            this.form_data_upd.state = this.prop_change_item.state;//状态
            this.form_data_upd.type = this.prop_change_item.type;//状态种类
            this.form_data_upd.meaning = this.prop_change_item.meaning;//状态含义
        },
        //提交
        onSubmit(){
            var submit_obj = {};
            if(this.prop_change_type === "upd"){//修改
                if(API.isEmtry(this.form_data_upd.state)){
                    this.$message.error("请填写状态");
                    return;
                }
                if(API.isEmtry(this.form_data_upd.meaning)){
                    this.$message.error("请填写状态含义");
                    return;
                }
                submit_obj.param = "update";
                submit_obj.state = this.form_data_upd.state;
                submit_obj.meaning = this.form_data_upd.meaning;
            }else{//增加
                if(API.isEmtry(this.form_data.state)){
                    this.$message.error("请填写状态");
                    return;
                }
                if(API.isEmtry(this.form_data.meaning)){
                    this.$message.error("请填写状态含义");
                    return;
                }
                submit_obj.param = "add";
                submit_obj.state = this.form_data.state;
                submit_obj.meaning = this.form_data.meaning;
            }
            
            
            API.ChannelStateUnifyServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },
    }
};
</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>